@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: "Inter", sans-serif;
    background-color: #0f172a;
}

* {
    box-sizing: border-box;
  }
  